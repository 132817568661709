import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '../../components/LayoutPreowned'
import YouTube from '../../components/homepage/YouTube'
import ProductGrid from '../../components/products/ProductGrid'
import RolexCategoryBtns from '../../components/preowned/RolexCategoryBtns'

// markup
const RolexYachtMaster = () => {
  const data = useStaticQuery(
    graphql`
      query queryRolexYachtMaster {
        products: allStrapiProduct(
          filter: { brand: { eq: "Rolex" }, model: { regex: "/Yacht-Master/" }, qty: { gt: 0 } }
          sort: { fields: stock_date, order: DESC }
          limit: 8
        ) {
          nodes {
            ...ProductCard
          }
        }
      }
    `
  )
  return (
    <Layout
      siteTitle={'Used Rolex Yacht Master Watches for Sale'}
      canonical={'/fine-watches/rolex/rolex-yacht-master/'}
      description={
        'Gray & Sons are experts in buying and selling second hand Rolex YachtMaster Watches with over 40 years of experience in the business'
      }
    >
      <div className="flex justify-center align-middle flex-col">
        <section>
          <div className="bg-gray-500 w-full flex justify-center">
            <a href="/fine-watches/rolex/rolex-yacht-master/">
              <StaticImage
                src="../../images/preowned/preowned-certified-used-rolex-yacht-master-header.png"
                alt="Pre-Owned Certified Used Rolex Yacht-Master Watches Header"
                aria-label="Used Rolex Yacht-Master Watch Site Header"
              />
            </a>
          </div>
        </section>

        <section className="p-5 lg:px-0 w-full flex justify-center">
          <div className="w-full md:w-3/4 lg:w-3/4 flex justify-center flex-col">
            <h1 className="pt-20">USED ROLEX YACHT MASTER</h1>
            <h2>
              ROLEX YACHT-MASTER: PRE-OWNED, ESTATE, VINTAGE, ANTIQUE, HEIRLOOM, USED ROLEX WATCHES{' '}
            </h2>
            <h3>BUY, SELL, TRADE, AND REPAIR USED AND PRE OWNED ROLEX YACHT-MASTER WATCHES</h3>
            <p>
              <br />
              <b>About Used Rolex Yacht-Master Watch</b>
              <br />
              <br />
              Introduced in 1992, the Yacht-Master is one of Rolex’s most luxurious sports watches.
              The Rolex Yacht-Master is a nautical-inspired watch, complete with a water-resistant
              case topped with a bidirectional rotating bezel. One of the Yacht-Master’s defining
              design traits is a bezel with large raised numbers. Over the last 30 years, Rolex has
              expanded the Yacht-Master lineup into a varied collection with plenty of sizes and
              materials to choose from.
              <br />
              <br />
              Thankfully, as an independent Rolex Yacht-Master dealer,{' '}
              <a href="/fine-watches/rolex/used-rolex-daytona/">www.grayandsons.com </a> benefits
              from both inventory control and price flexibility. Whether vintage, discontinued, or
              current, Gray &amp; Sons offers a large inventory of pre-owned Rolex Yacht-Master
              watches, available in stock right now, ready to buy. Reach out to one of our
              <a href="/chat-with-rich/">decision-makers </a>
              to secure a used Rolex Yacht-Master watch before it’s too late. However, if you’ve
              been wondering, “where can I sell my used Rolex Yacht-Master,” then visit
              <a href="https://sellusyourjewelry.com/">www.sellusyourjewelry.com</a>
              . so one of our watch experts can get you the best cash offer today.
              <br />
              <br />
              <b>Buying and Selling Used Rolex Yacht-MasterYacht-Master Watches</b>
              <br />
              <br />
              When buying a pre-owned Rolex Yacht-Master watch, there are a few things to keep in
              mind. First of all, there are different sizes to choose from: 42mm, 40mm, 37mm 35mm
              (discontinued), and 27mm (discontinued). Furthermore, secondhand Rolex Yacht-Master
              watches are available in a range of materials: yellow gold, white gold, Everose gold,
              stainless steel with platinum bezels (also known as Rolesium), two-tone steel and
              Everose gold, and two-tone steel and yellow gold (discontinued). Finally, depending on
              the specific Yacht-Master reference, the watches are fitted with metal bezels or
              ceramic bezels, as well as three-link metal Oyster bracelets or rubber Oysterflex
              bracelets.
              <br />
              <br />
              <i>Popular Pre-Owned Used Rolex Yacht-Master Watch References:</i>
              <br />
              <br />
              <ul>
                <li>
                  Yellow gold Yacht-Master: ref. 16628 (40mm), ref. 68628 (35mm), ref. 69628 (29mm)
                </li>
                <li>
                  Steel and platinum Yacht-Master: ref. 16622, 116622, and 126622 (40mm), ref. 68622
                  and 168622 (35mm), ref. 69622 and 169622 (29mm)
                </li>
                <li>
                  Steel and yellow gold: ref. 16623 (40mm), ref. 68623 and 168623 (35mm), ref. 69623
                  and 168623 (29mm)
                </li>
                <li>Everose gold and ceramic: ref. 116655 and 126655 (40mm), ref. 268655 (37mm)</li>
                <li>Everose gold and steel: ref. 116621 and 126621 (40mm), ref. 268621 (37mm)</li>
                <li>White gold and ceramic: ref. 226659 (42mm)</li>
                <li>Yellow gold and ceramic: ref. 226658: (42mm)</li>
              </ul>
              <br />
              <br />
              <b>Why Gray &amp; Sons is The Best Source for Buying and Selling Rolex</b>
              <br />
              <br />
              Gray &amp; Sons is proud to have served countless happy customers over the last 40
              years at both our physical store and premier pre-owned luxury watch e-commerce
              website. Our dear clients have called Gray &amp; Sons the number one seller and buyer
              of used Rolex Yacht-Master watches.
              <br />
              <br />
              Open six days a week, the Gray &amp; Sons store, which is next to the Bal Harbour
              Shops serves the Miami, Miami Beach, Coral Gables, Coconut Grove, South Beach, Bal
              Harbour, Surfside, Sunny Isles, Ventura, Fort Lauderdale, Pinecrest, and Brickle
              areas. Whether on our website or in-store, Gray &amp; Sons watch experts are ready to
              help you buy or sell secondhand Rolex Yacht-Master watches. There is also the Gray
              &amp; Sons independent Rolex Yacht-Master repair facility for any watch maintenance
              requirements.
              <br />
              <br />
              <br />
            </p>
            <div className="w-full flex justify-start">
              <a href="/fine-watches/rolex-yacht-master/">
                <button>SHOP ROLEX YACHT-MASTER WATCHES</button>
              </a>
            </div>
            <br />
            <br /> <br />
            <br />
          </div>
        </section>
      </div>

      <section>
        <h2 className="text-center py-10">IN-STOCK ROLEX YACHT-MASTER WATCHES</h2>
        <ProductGrid products={data.products.nodes} />
      </section>

      <section>
        <h2 className="text-center py-10">EXPLORE MORE GREAT WATCH STORIES FROM GRAY &amp; SONS</h2>
        <RolexCategoryBtns />
      </section>

      <section className="py-20">
        <h2 className="text-center py-10">VISIT GRAY &amp; SONS ON YOUTUBE</h2>
        <div className="w-full flex justify-center">
          <YouTube
            className={'w-full lg:w-3/4 xl:w-1/2 py-10 aspect-video'}
            title={'You Tube Video Title '}
            src={'https://www.youtube.com/embed/KH7qSClYNhs'}
          />
        </div>
      </section>
    </Layout>
  )
}

export default RolexYachtMaster
